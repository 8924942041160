import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/8A/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/8A/2.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly8A2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 2,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 2,
    },
  
    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      RADIOACTIVITY
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        CLASS: 8A   &  Date: 15 SEPTEMBER 2023
        </Typography>

        <br />
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Life is a state of constant radiation and absorption; to exist is to radiate; to exist is to be the recipient of radiations. — William George Jordan”
<br/>
        </Typography>
        <br/>
        <Typography variant={'subtitle1'} align={'justify'}>
        The above statement truly depicts the essence of the phenomenon of radioactivity, which was the theme of 8A class assembly. The class assembly was conducted over two days. The concept of radioactivity was introduced in the form of a skit showing neutron bombardment and the splitting of an unstable nucleus. This was followed by interesting facts.
        <br></br>
        The harmful and useful effects of radiation was also highlighted in the assembly. This was followed by a foot-tapping dance on the same theme. 
        <br></br>
        The commendable part was that every student participated in the class assembly and got an opportunity to exhibit their talent. The audience were able to learn about radiation in a simple but interesting way. Finally, it can be said that the phenomenon of radioactivity is here to stay and not to be feared. We need to accept the new forms of energy but need to be careful about misuse by humans.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Every thought has a frequency and magnetic energy. The more positive energy you throw into the universe, the more positive energy it radiates back.”
<br/>
        </Typography>


    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 400 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly8A2023;